$(function () {
    $('#slider_pro').sliderPro({
        //width: 855,
        height: 365,
        responsive: true,
        orientation: 'vertical',
        loop: false,
        arrows: false,
        buttons: false,
        thumbnailsPosition: 'right',
        thumbnailPointer: true,
        thumbnailWidth: 280,
        thumbnailHeight: 100,
        init: function () {
            $('#slider_pro').removeClass('hidden');
            return true;
        },
        breakpoints: {
            1024: {
                thumbnailsPosition: 'right',
                thumbnailWidth: 230,
                thumbnailHeight: 85
            },
            855: {
                thumbnailsPosition: 'bottom',
                thumbnailWidth: 475,
                thumbnailHeight: 85
            },
            500: {
                thumbnailsPosition: 'bottom',
                thumbnailWidth: 475,
                thumbnailHeight: 85
            }
        }
    });

    $("body").fitVids();

    $('.nyroModal').colorbox();

    $(document).on('click', '.bookmarkme', function (e) {
        e.preventDefault();
        if (window.sidebar && window.sidebar.addPanel) {
            window.sidebar.addPanel(document.title, window.location.href, '');
        } else if (window.external && ('AddFavorite' in window.external)) {
            window.external.AddFavorite(location.href, document.title);
        } else if (window.opera && window.print) {
            this.title = document.title;
            return true;
        } else {
            alert('Нажмите ' + (navigator.userAgent.toLowerCase().indexOf('mac') != -1 ? 'Command/Cmd' : 'CTRL') + ' + D для добавления сайта в закладки.');
        }
    });
});
